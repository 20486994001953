<script>
  import ColourCycle from "./ColourCycle.svelte";
  import Typewriter from "svelte-typewriter";
</script>

<div class="centered"> 
  <Typewriter interval={50} loop><!-- prettier-ignore -->
    <h1 class="typewriter-text">Let's make <span class="blue">Marriott</span> great again</h1>
    <h1 class="typewriter-text">Let's turn Marriott's hair <span class="blue">blue</span> for <span class="green">charity</span></h1>
    <h1 class="typewriter-text">Backed by Staff and Students</h1>
    <h1 class="typewriter-text">All proceeds go to <span class="red">CRY</span></h1>
    <h1 class="typewriter-text"><span class="green">Donation</span> page coming soon!</h1>
    <!-- prettier-ignore-end -->
  </Typewriter>
</div>
<ColourCycle />

<style>
  .typewriter-text {
    color: white;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    text-shadow: 0 0 5px black;
  }

  .green {
    color: greenyellow;
  }

  .red {
    color: firebrick;
  }
  .blue {
    color: dodgerblue;
  }

  h1 {
    font-weight: 700;
    clear: both;
    color: #1f2b35;
    font-family: "Mukta", sans-serif;
    font-size: 4rem;
  }
</style>
